<template>
	<div style="margin-top: 20px;">
		<div class="sameWidth">
			<div style="margin:0;">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/companyActive' }">公司业务</el-breadcrumb-item>
					<el-breadcrumb-item>招投标咨询</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div style="margin:0;text-align: center;">
				<h1 class="font2" style="margin-bottom: 50px;">招投标咨询</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="width: 50%;display: inline-block;vertical-align: middle;">
					<img src="../../../assets/images/companyActive/公司业务—招投标咨询.jpg" style="width: 100%;"/>
				</div><div style="width: 50%;display: inline-block;vertical-align:top;">
					<p style="text-indent: 2em;padding-left: 20px;font-size: 16px;color: #595959;line-height: 30px;text-align: left;" >
						招投研究院具有专业的招投标咨询专家团队。他们具有丰富的招投标理论知识和实践经验，应用科学、前沿的方法，找出企业在招投标管理方面存在的问题，分析原因，提出改进方案，并指导企业将改进方案实施落地，改善企业的招投标管理水平。
					</p>
				</div>
			</div>
			<div style="margin: 100px 0;text-align: center;">
				<h1 style="margin-bottom: 50px;" class="font2">招标采购管理模型</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<img src="../../../assets/images/tenderSultation/u507.png" class="image" />
			</div>
			<div style="margin: 100px 0;text-align: center;">
				<h1 style="margin-bottom: 50px;" class="font2">服务流程</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div id="liuCheng">
					<div class="iconDiv">
						<p class="iconfont icon-kehuxuqiu white"></p>
						<h4>获知客户需求</h4>
					</div>
					<div class="iconDiv2">
						<p class="iconfont icon-jiantou"></p>
					</div>
					<div class="iconDiv">
						<p class="iconfont icon-qiandingxieyi white"></p>
						<h4>签订委托协议</h4>
					</div>
					<div class="iconDiv2">
						<p class="iconfont icon-jiantou"></p>
					</div>
					<div class="iconDiv">
						<p class="iconfont icon-shichangtiaoyan white"></p>
						<h4>进行市场调查</h4>
					</div>
					<div class="iconDiv2">
						<p class="iconfont icon-jiantou"></p>
					</div>
					<div class="iconDiv">
						<p class="iconfont icon-baogao white"></p>
						<h4>生成咨询报告</h4>
					</div>
					<div class="iconDiv2">
						<p class="iconfont icon-jiantou"></p>
					</div>
					<div class="iconDiv">
						<p class="iconfont icon-zhiliangkongzhi white"></p>
						<h4>项目质量控制</h4>
					</div>
					<div class="iconDiv2">
						<p class="iconfont icon-jiantou"></p>
					</div>
					<div class="iconDiv">
						<p class="iconfont icon-zhuanjialunzhenghuiyi white"></p>
						<h4>组织专家论证</h4>
					</div>
				</div>
			</div>
			<div style="margin: 100px 0;text-align: center;display: none;" class="sameWidth">
				<h1 style="margin-bottom: 50px;" class="font2">经典案例</h1>
				<h1 class="borderBottom"><span>&nbsp;</span></h1>
				<div style="display: flex;" class="banner4">
					<div style="flex:1;padding: 0;" v-for="item in youshi" :key="item.id">
						<div style="margin:20px;box-shadow: 10px 10px 5px #888888;height: 100%;">
							<img :src="item.src" class="image" />
							<div style="padding:0 20px">
								<h3>{{ item.title }}</h3>
								<p style="color: #909399;text-align: left;line-height: 25px;">{{ item.desc }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
</div>
</template>
<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
	}
	.borderBottom{
		margin-top: -40px;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 50px;
	}
	#liuCheng{
		display: flex;background-color:#409EFF;color: #fff;padding: 20px;
	}
	#liuCheng .iconDiv{
		flex-grow: 2;width: 0;padding-top: 20px;
	}
	#liuCheng .iconDiv2{
		flex-grow: 1;width: 0;padding-top: 20px;
	}
	#liuCheng .iconDiv2 .icon-jiantou{
		font-size:40px;
	}
	#liuCheng .iconDiv .white{
		display:inline-block;
		width: 100px;
		height: 100px;
		line-height: 100px;
		border-radius: 50%;
		font-size:60px;
		background-color: #fff;
		box-sizing: border-box;
		margin: 0;
		color:#3281B9;
	}
	.image{width: 100%;}

</style>


<script>
export default {
  data() {
    return {
		screenWidth:1300,
      headersrc: require("../../../assets/image/公司前台.jpg"),
      yewu: [
        {
          id: 1,
          src: require("../../../assets/image/精品标书.png"),
          title: "招标代理",
          desp: "招标代理",
        },
        {
          id: 2,
          src: require("../../../assets/image/培训服务.png"),
          title: "招投标咨询",
          desp: "招投标咨询",
        },
        {
          id: 3,
          src: require("../../../assets/image/咨询服务.jpg"),
          title: "招投标培训",
          desp: "招投标培训",
        },
        {
          id: 4,
          src: require("../../../assets/image/信息化服务.png"),
          title: "招标审计",
          desp: "招标审计",
        },
        {
          id: 5,
          src: require("../../../assets/image/信息化服务.png"),
          title: "招投标专题研究",
          desp: "招投标专题研究",
        },
      ],
      serviceprocessing: [
        {
          id: 1,
          src: require("../../../assets/images/tenderSultation/u507.png"),
          title: "招标代理",
          desp: "招标代理",
        },
        {
          id: 2,
          src: require("../../../assets/image/招标代理流程专业服务.png"),
          title: "招标代理流程专业服务",
          desp: "招标代理流程专业服务",
        },
      ],
      servicesuperiority: [
        {
          id: 1,
          src: require("../../../assets/image/精品标书.png"),
          title: "业务经验丰富",
          desp: "业务经验丰富",
        },
        {
          id: 2,
          src: require("../../../assets/image/精品标书.png"),
          title: "服务便利性",
          desp: "招标采购全过程审计",
        },
        {
          id: 3,
          src: require("../../../assets/image/精品标书.png"),
          title: "专业团队",
          desp: "招标代理流程专业服务",
        },
        {
          id: 4,
          src: require("../../../assets/image/精品标书.png"),
          title: "良好口碑",
          desp: "招标代理流程专业服务",
        },{
          id: 5,
          src: require("../../../assets/image/精品标书.png"),
          title: "业务经验丰富",
          desp: "业务经验丰富",
        },
        {
          id: 6,
          src: require("../../../assets/image/精品标书.png"),
          title: "服务便利性",
          desp: "招标采购全过程审计",
        },
        {
          id: 7,
          src: require("../../../assets/image/精品标书.png"),
          title: "专业团队",
          desp: "招标代理流程专业服务",
        },
        {
          id: 8,
          src: require("../../../assets/image/精品标书.png"),
          title: "良好口碑",
          desp: "招标代理流程专业服务",
        },
      ],
      example: [
        {
          id: 1,
          src: require("../../../assets/image/精品标书.png"),
          title: "业务经验丰富",
          desp: "业务经验丰富",
        },
        {
          id: 2,
          src: require("../../../assets/image/精品标书.png"),
          title: "服务便利性",
          desp: "招标采购全过程审计",
        },
        {
          id: 3,
          src: require("../../../assets/image/精品标书.png"),
          title: "专业团队",
          desp: "招标代理流程专业服务",
        },
        {
          id: 4,
          src: require("../../../assets/image/精品标书.png"),
          title: "良好口碑",
          desp: "招标代理流程专业服务",
        },
      ],
		youshi: [
			{
			id: 1,
			src: require("../../../assets/images/tenderAgent/经验丰富.jpg"),
			title: "业务经验丰富",
			desc:'　　核心团队具有十余年招标代理、政府采购代理、招标规范化审计经验，累计完成代理额超过100亿元。',
			},
			{
			id: 3,
			src: require("../../../assets/images/tenderAgent/专业团队.jpg"),
			title: "专业团队",
			desc:'　　招投研究院80%以上为本科以上学历或中高级以上职称；聘请了原广东省财政厅政府采购监管处退休专家、,华南理工大学工商管理学院教授、广东正大方略律师事务所高级合伙人等一批专家团队，强大的专家团队确保项目顺利履行。',
			},
			{
			id: 4,
			src: require("../../../assets/images/tenderAgent/良好口碑.jpg"),
			title: "良好口碑",
			desc:'　　近年来服务广东、湖南、湖北、山东、广西、云南等地区的 300 多家企事业单位，取得了良好的社会声誉。目前主要行业包括：,（1）电力电网及其供应商；（2）运营商及其供应商；（3）政府采购各行各业优秀供应商。',
			}
		],
    };
  },
	mounted:function(){
		this.bus.$emit('headerImg',this.headerImg);
		this.screenWidth=document.documentElement.clientWidth;
		window.addEventListener('resize',()=>{
			this.screenWidth=document.documentElement.clientWidth;
		})
	}
};
</script>
